import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/packages/swarm-docs/src/components/layout-mdx.js";
import { Checkbox } from '@meetup/swarm-components';
import Example from '../../components/examples/checkbox';
import PropsTable from '../../components/propstable';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Checkbox`}</h1>
    <hr></hr>
    <p>{`A checkbox allows a user to select a value from a small set of options, often binary.`}</p>
    <h2>{`Example`}</h2>
    <Example mdxType="Example" />
    <h1>{`Snippets`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-.jsx"
      }}>{`<>
<Checkbox label="do this thing" checked={true === true} />
<Checkbox label="also this thing" checked={true === false} />
</>
`}</code></pre>
    <h1>{`Props`}</h1>
    <PropsTable propMetaData={Checkbox.__docgenInfo} mdxType="PropsTable" />
    <h2>{`a11y`}</h2>
    <p>{`Keyboard interactions: Space should activate the checkbox.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      